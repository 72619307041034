<template>
  <div>  
      <div style="max-width: 850px;"> 
        <el-alert v-show="res_Error.length > 0" :title="res_Error" type="error" show-icon> </el-alert>
         
         <div v-if="newItem">
                <h3 class="title"></h3>                
          </div> 
          <div v-else>
                <div style="display: inline-block"><h3 class="title">Device: {{userForm.core_id}}</h3>                
                </div> 
                <div style="display: inline-block;float: left;">
                      FW version: {{userForm.ver}}    
                </div>
                <div style="display: inline-block;float: left; margin-left: 200px">
                      Online: {{userForm.online}}
                </div>
                <div style="display: inline-block;float: right;">
                  <el-button    type="danger" @click="deleteClick('userForm')">Delete</el-button>
                </div>
          </div> 
             
         <el-menu
          :default-active="activeIndex2"
            class="el-menu-demo"
            mode="horizontal"
            @select="menuSelect"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b">
            <el-menu-item index="setup" >Setup</el-menu-item>
            <el-menu-item index="channels" :disabled="newItem == true">Channels</el-menu-item>
            <el-menu-item index="script" :disabled="newItem == true">Scripts</el-menu-item>
            <el-menu-item index="files" :disabled="newItem == true">Files</el-menu-item>
            <el-menu-item index="control" :disabled="newItem == true">Control</el-menu-item>
          </el-menu>
          <router-view></router-view>      
      </div>      
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {   
    return {
      res_Error: "",
      activeIndex2: 'setup',
      userForm: {
        id: 0,
        Description: "",
        GW_id: "",
        Name: "",
        channels: {},               
      },
      newItem: false
    };
  },

  watch: {
    $route(to, from) {  
          
      //this.getUser(to.params.gatewayId);
    }
  },
  created() {
    //alert(this.$route.params.teamId);
    this.$router.push({ path: `/device/${this.$route.params.devID}/setup`}); 
  },

  methods: {
    menuSelect: function(event) {
      console.log(event);      
      this.$router.push({ path: `/device/${this.$route.params.devID}/${event}`}); 
    },
    goBack() {      
      this.$router.go(-1);
    },
    getItem(itemId) {
      if (itemId == "new") {
        this.newItem = true;
        //this.userForm.FirstName ="";
        return;
      }
      console.log("getting item" + itemId);
      let command = {
        command: "get_item",
        params: {
          id: itemId
        }
      };
      this.$http        
        .get("/devices/"+ itemId)
        .then(request => {
          console.log(request);
          if (request.data.data.length > 0) {
            this.userForm = request.data.data[0];
            if (request.data.data[0].body)
            {
              let attributes =  JSON.parse(request.data.data[0].body);
              this.userForm.channels = attributes.channels;
            }
            else
              this.userForm.channels = {};           
          }
          //this.form = request.data.result;
        })
        .catch(() => {
          console.log("auth error");
        });
    },
  },   

  mounted() {
    this.getItem(this.$route.params.devID);
  },
  computed: mapState(["user_profile"])
};
</script>