<template>
  <el-container style="height: 900px; border: 1px solid #eee ; background: #e9eef3">    
  <el-header style="text-align: center; font-size: 14px; height: 60px ;line-height: 60px;">
            <div style="display: inline-block; font-size: 28px; color: #0867a5">Oak IOT System</div> 
            <div style="display: inline-block; float: right;">
              <!--<el-button   v-on:click="logout" type="danger">Logout</el-button>-->
              <el-dropdown @command="handleCommand" split-button type="primary">
                {{user_profile.firstname}}
                <!--<i class="el-icon-setting" style="margin-right: 15px"></i>-->
                <el-dropdown-menu slot="dropdown">
                   <el-dropdown-item command="profile">My Profile</el-dropdown-item>
                  <el-dropdown-item command="logout">Logout</el-dropdown-item>                
                </el-dropdown-menu>
              </el-dropdown>
              <!--<span>{{user_profile.FirstName}}</span>-->
              </div>
        </el-header>
  <el-container>
    <el-aside width="210px" style="background-color: rgb(238, 241, 246)">        
    <el-menu
      :default-active="activeLink"
      class="el-menu-vertical-demo"      
      @select="menuSelect"
      background-color="#545c64"
      text-color="#fff"     
      active-text-color="#ffd04b">
      <el-menu-item index="/users" v-if="user_profile.username=='admin'">
        <font-awesome-icon icon="user-friends" />
        <span>Users</span>
      </el-menu-item>      
      
      <el-menu-item index="/devices">
        <font-awesome-icon icon="microchip" />
        <span>Devices</span>
      </el-menu-item>

      <el-menu-item index="/ota">
        <font-awesome-icon icon="sitemap" />
        <span>OTA</span>
      </el-menu-item>

      <el-menu-item index="/webhooks">
        <font-awesome-icon icon="cog" />
        <span>Webhooks</span>
      </el-menu-item>
      
      <el-menu-item index="4">
        <font-awesome-icon icon="coffee" />
        <span>About</span>
      </el-menu-item>
     
     <el-menu-item index="/help.html">
        <font-awesome-icon icon="question" />
        <span>Help</span>
      </el-menu-item>      

    </el-menu>
    </el-aside>
    <el-main>      
     <router-view></router-view>
    </el-main>
  </el-container>
  <el-footer style= "height: 20px; color: #0867a5"> © 2018-2019 oak-art</el-footer>
</el-container>

</template>

<script>

import { logout, getAccessToken } from "./utils/auth";
import { mapState, mapMutations } from "vuex";
import EventBus from "./utils/event-bus"; //./utils/auth

export default {
  data() {
    return {
      activeLink: null
    };
  },

  methods: {
    ...mapMutations(["set_profile"]),
    logout: function(event) {
      logout();
      //this.$router.replace("/login");
      this.$router.push("/Login");
    },

    getProfile() {
      const idToken = getAccessToken();      
      this.$http
        .get("/users/"+idToken.id)
        .then(request => {          
          let _profile = request.data.data[0];           
          this.set_profile(_profile);
        })
        .catch(() => {
          console.log("profile error");
        });
    },

    menuSelect: function(event) {
      console.log(event);
      this.$router.push(event);
    },

    handleCommand(command) {
      switch (command) {
        case "logout":
          this.logout();
          break;
        case "profile":
          //<router-link :to="{name: 'User', params:{userId: scope.row.id}}">{{ scope.row.id }}</router-link>
          //alert(this.user_profile.id);
          this.$router.push({
            name: "User",
            params: { userId: this.user_profile.id }
          });
          break;
      }
      // this.$message('click on item ' + command);
    }
  },

  mounted() {
    this.getProfile();
    //this.$router.push("/users"); // default
    var this_1 = this;
    EventBus.$on("Network error", function(payLoad) {
      if (payLoad.message === "Failed to authenticate token.") {
        this.$alert(
          "Your session has expired. Please login again.",
          "Session has expired",
          {
            confirmButtonText: "OK",
            callback: action => {
              this_1.logout();
            }
          }
        );
        return;
      }

      if (payLoad.message === "jwt expired") {
        this.$alert(
          "Your session has expired. Please login again.",
          "Session has expired",
          {
            confirmButtonText: "OK",
            callback: action => {
              this_1.logout();
            }
          }
        );
        return;
      }

      if (payLoad.message === "Forbidden") {
        this.$message.error("Not Authorized.");
        this_1.$router.push("/");
        return;
      }

      this.$message.error(payLoad.message);
    });
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.activeLink = to.path;
    });
  },

  beforeRouteUpdate(to, from, next) {
    this.activeLink = to.path;
    next();
  },

  computed: mapState(["count", "user_profile"])
};
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>

<style>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>

<style>
.el-header {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e0e9ff+0,b3c0d1+100 */
  background: #e0e9ff; /* Old browsers */
  background: -moz-linear-gradient(top, #e0e9ff 0%, #b3c0d1 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e0e9ff 0%,#b3c0d1 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e0e9ff 0%,#b3c0d1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0e9ff', endColorstr='#b3c0d1',GradientType=0 ); /* IE6-9 */ 


  /*background-color: #b3c0d1;*/
  color: #330;
  text-align: center;
  margin-bottom: 10px;
}

.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  height: 10px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: left;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  /*line-height: 200px;*/
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>
