<template>
  <div style="max-width: 600px;">
    <div>
       <div style="display: inline-block;">
         <h4>Users</h4>
      </div>
      <div style="display: inline-block;float: left;">
         <el-button type="primary" v-on:click="addUser">
           <font-awesome-icon icon="user-plus" size="1x"/>
           New
           </el-button>
      </div> 
    </div>      
<el-table
    ref="usersTable"
    :data="tableData"
    border    
    highlight-current-row
    @current-change="handleCurrentChange"
    @sort-change="onSort"
    style="width: 100%">
    <el-table-column
      property="username"
      label="Name"
      sortable
      >
      <template slot-scope="scope">
        <router-link :to="{name: 'User', params:{userId: scope.row.id}}">{{ scope.row.username }}</router-link>
      </template>
    </el-table-column>
    <el-table-column
      property="firstname"
      label="FirstName"
      sortable
      width="120">
    </el-table-column>
    <el-table-column
      property="lastname"
      label="lastname"
      sortable
      width="120">
    </el-table-column>
    <el-table-column
      property="email"
      label="Email">
    </el-table-column>
  </el-table>
  <el-pagination
     background
     @current-change="pageChange" 
     layout="prev, pager, next"
     :current-page=currentPage 
     :page-size=pageSize 
     :total=total>
  </el-pagination>


  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"; // Add mapMutations
export default {
  watch: {
    $route(to, from) {
      //alert(to.params.teamId);
    }
  },
  created() {
    //alert(this.$route.params.teamId);
  },

  methods: {
    handleCurrentChange() {},

    ...mapMutations([     
      "set_users_table_page",
      "set_users_table_sort"
    ]),

    onSort(event) {
      
      this.sortField = event.prop;           
      if (event.order == "ascending") this.sortOrder = 1;
      else this.sortOrder = -1;
      if (!event.order) this.sortOrder = 1;

      //console.log("sort");
      //console.log(event);
      
      this.set_users_table_sort({sortField: event.prop, sortOrder: event.order});// save sort in store..
      this.getUsers();
    },

    pageChange(page) {
      this.firstRow = this.pageSize * (page - 1);
      this.set_users_table_page(page);      
      this.getUsers();
    },

    goHome() {
      this.$router.push("/");
    },

    restoreParams(){        
        let page = this.users_table.page;
        this.firstRow = this.pageSize * (page - 1);        
        this.$refs.usersTable.sort(this.users_table.sortField, this.users_table.sortOrder);
        if (this.users_table.sortField == null) this.getUsers(); //   
    },

    getUsers() {
      console.log("getting users");  
      let params = {
        page : this.users_table.page,
        pageSize : this.pageSize,
        sortField: this.sortField,
        sortOrder: this.sortOrder
      }
      //console.log(command);
      this.$http
        .patch("/users", params)
        .then(request => {
          console.log(request);
          this.tableData = request.data.data.data;
          this.total = request.data.data.total;
          this.currentPage = this.users_table.page;//<---- set page from the store..
          //this.$refs.usersTable.sort({prop: this.users_table.sortField, sort: this.users_table.sortOrder}); 
          //this.$refs.usersTable.sort(this.users_table.sortField, this.users_table.sortOrder); 
        })
        .catch((error) => {
          console.log("auth error");
          console.log(error);
        });
    },
    addUser() {      
      this.$router.push("/users/new");
    }
  },
  data() {
    return {
      tableData: [],
      total: 0,
      pageSize: 5,
      currentPage: 1,
      firstRow: 0,
      sortField: null,
      sortOrder: 0,
      currentRow: null,
      
    };
  },
  mounted() {    
    this.restoreParams();    
  },


  computed: {
    localComputed(){
        //this.currentPage = this.users_table.page;
    },
    ...mapState(["users_table"])
  }
};
</script>

<style>
.el-table {
  color: rgb(121, 4, 4);
  padding: 5px 5px;
}

/*---------------------   http://www.colorzilla.com/gradient-editor/ ---*/
.el-table thead th {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=0 );

  padding: 5px 5px;
  color: #000;
}
</style>
