<template>
<div style="max-width: 850px; text-align: left;"> 
    <el-alert v-show="res_Error.length > 0" :title="res_Error" type="error" show-icon> </el-alert>
    <!--
    <div v-if="newItem">
        <h3 class="title"></h3>                
    </div>
    -->

    <div>
         
        <div style="display: inline-block" v-if="newItem"><h3 class="title">New Webhook</h3></div> 
        <div style="display: inline-block" v-else><h3 class="title">Webhook: {{userForm.description}}</h3></div>  

        <div   v-if="newItem" style="display: inline-block;float: right;">
          <el-button @click="goBack">Cancel</el-button>  
        </div>        
        <div v-else style="display: inline-block;float: right;">
          <el-button    type="danger" @click="deleteClick('userForm')">Delete</el-button>
        </div>

        <el-menu
          :default-active="activeIndex2"
            class="el-menu-demo"
            mode="horizontal"
            @select="menuSelect"
            background-color="#545c64"
            text-color="#fff"           
            active-text-color="#ffd04b">
            <el-menu-item index="builder" >WEBHOOK BUILDER</el-menu-item>
            <el-menu-item index="template" >CUSTOM TEMPLATE</el-menu-item>            
          </el-menu>
          
         <!--<router-view></router-view>--> 
         <!-- 
          <el-form ref="userForm"  v-if="newItem" :model="userForm"  status-icon :rules="rules_new"  label-width="120px" size="mini" key="new-form">
          </el-form>
         -->


        <el-form ref="userForm"  style="padding-top: 20px;"   :model="userForm"  status-icon :rules="rules_item"  label-width="120px" size="mini" key="user-form">
        
        <el-form-item label="Event Name:" prop="name">
            <el-col :span="8" > 
             <el-input v-model="userForm.name"></el-input>
            </el-col>            
        </el-form-item>
        
        <el-form-item label="Description:" prop="description">
           <el-col :span="8" > 
             <el-input v-model="userForm.description"></el-input>
            </el-col>
        </el-form-item>
        

        <el-form-item label="URL:" prop="url">
        <el-input v-model="userForm.url"></el-input>
        </el-form-item>         


        <el-form-item label="Device ID:" prop="device">
            <el-col :span="8"> 
                <el-input v-model="userForm.deviceId" placeholder="any"></el-input>
           </el-col>
        </el-form-item>

        
        <!------------ bulder only-------->
        <div v-if="showBulder">
       

        <el-form-item label="Request type:">
            <el-col :span="8">
              <el-select v-model="userForm.requestType" placeholder="please select request type">
              <el-option label="GET" value="get"></el-option>
              <el-option label="POST" value="post"></el-option>
              <el-option label="PUT" value="put"></el-option>
              <el-option label="DELETE" value="delete"></el-option>
              </el-select>
            </el-col>
        </el-form-item> 
        
        <el-form-item>   
          <el-collapse>            
            <el-collapse-item title="Header fields" name="2" style="text-align: center;">                
                
                <el-form-item 
                 v-for="(header, index) in userForm.headers" v-bind:key="header.key"                 
                 :prop="'headers.' + index + '.value'"                 
                >
                
                <el-col class="line" :span="1">-</el-col>
                <el-col :span="8">
                <el-input v-model="header.header" placeholder="enter header"></el-input>
                </el-col>
                <el-col class="line" :span="2">></el-col>
                <el-col :span="8">
                  <el-input v-model="header.value" placeholder="enter header value"></el-input>
                </el-col> 
                <el-col :span="2">
                    <el-button type="info" icon="el-icon-delete" circle @click="deleteHeader(index)"></el-button> 
                </el-col>  
                
                </el-form-item>
                
                <el-button type="info" @click="addHeader">Add row</el-button>
            </el-collapse-item>    
          </el-collapse>
        </el-form-item>   

        <el-form-item label="Use authentication" prop="delivery">
           <el-switch v-model="userForm.authentication"></el-switch>
        </el-form-item>

        <el-form-item v-if="userForm.authentication" label="" prop=""> 
        <el-collapse>
            <el-collapse-item title="Authentication" name="1" style="text-align: center;">                
                <el-form-item label="Username:" prop="">
                <el-col class="line" :span="1"></el-col>
                <el-col :span="8">
                <el-input v-model="userForm.auth.username" placeholder="any"></el-input>
                </el-col>
                </el-form-item>
                <el-form-item label="Password:" prop="">
                <el-col class="line" :span="2"></el-col>
                <el-col :span="8">
                  <el-input v-model="userForm.auth.password" placeholder="any"></el-input>
                </el-col>
                </el-form-item>
                
            </el-collapse-item> 
        </el-collapse>  
        </el-form-item>


        

        <el-form-item v-if="userForm.requestType === 'get'">   
          <el-collapse>            
            <el-collapse-item title="Query" name="2" style="text-align: center;">                
                
                <el-form-item 
                 v-for="(header, index) in userForm.query" v-bind:key="header.key"                 
                 :prop="'query.' + index + '.value'"                 
                >
                
                <el-col class="line" :span="1">-</el-col>
                <el-col :span="8">
                <el-input v-model="header.header" placeholder="enter header"></el-input>
                </el-col>
                <el-col class="line" :span="2">></el-col>
                <el-col :span="8">
                  <el-input v-model="header.value" placeholder="enter header value"></el-input>
                </el-col> 
                <el-col :span="2">
                    <el-button type="info" icon="el-icon-delete" circle @click="deleteQuery(index)"></el-button> 
                </el-col>  
                
                </el-form-item>
                
                <el-button type="info" @click="addQuery">Add row</el-button>
            </el-collapse-item>    
          </el-collapse>
         </el-form-item>   



       <div v-if="userForm.requestType === 'post'">

        <el-form-item label="Request format:">
            <el-col :span="8"> 
             <el-select v-model="userForm.requestFormat" placeholder="please select request format">
              <el-option label="Web Form" value="webform"></el-option>
              <el-option label="JSON" value="json"></el-option>
              <el-option label="BODY" value="body"></el-option>
              </el-select>
              </el-col> 
        </el-form-item>  
        
        
        <el-form-item  v-if="userForm.requestFormat ==='webform'">   
          <el-collapse>            
            <el-collapse-item title="Form fields" name="1" style="text-align: center;">  
                <el-form-item 
                 v-for="(header, index) in userForm.fields" v-bind:key="header.key"                 
                 :prop="'fields.' + index + '.value'"                 
                >                
                <el-col class="line" :span="1">-</el-col>
                <el-col :span="8">
                <el-input v-model="header.header" placeholder="enter header"></el-input>
                </el-col>
                <el-col class="line" :span="2">></el-col>
                <el-col :span="8">
                  <el-input v-model="header.value" placeholder="enter header value"></el-input>
                </el-col> 
                <el-col :span="2">
                    <el-button type="info" icon="el-icon-delete" circle @click="deleteField(index)"></el-button> 
                </el-col>  
                
                </el-form-item>
                
                <el-button type="info" @click="addField">Add row</el-button>
            </el-collapse-item>    
          </el-collapse>
        </el-form-item>  
        </div>
       

       <el-form-item  v-if="userForm.requestFormat ==='json'" label="JSON:">
        <prism-editor v-model="userForm.json" language="js" :line-numbers="false" class="my-editor" />   
       </el-form-item> 

       <el-form-item  v-if="userForm.requestFormat ==='body'" label="Body:">
        <prism-editor v-model="userForm.body" language="js" :line-numbers="false" class="my-editor" />   
       </el-form-item> 

       <!------------ bulder only end -------->
       </div>
       <!-- custom template only --------------> 
       <div v-else>
         <el-form-item  label="Custom template:">
           <prism-editor v-model="hookAsJSON" language="js" :line-numbers="false" class="my-editor" />   
         </el-form-item> 
       </div>
       <!-- custom template only end -------------->


        <el-form-item>        
             <el-button type="primary" @click="submitForm('userForm')">Submit</el-button>             
             <el-button v-if="!newItem" @click="goBack">Back</el-button>             
        </el-form-item> 




        </el-form>
    </div> 
</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import PrismEditor from "vue-prism-editor";
export default {
  data() {   
    return {
      res_Error: "",
      activeIndex2: 'builder',
      showBulder: true,
      userForm: {
          name: "",
          url: "",
          requestType: "get",
          deviceId: "",
          requestFormat: "",
          headers: [],
          fields: [],
          authentication: false,
          auth:{},
          query: [],
          json:"",
          body:"",
          description: "",                     
      },
      
      rules_item: {
        description: [
          {
            required: true,
            message: "Please enter Description",
            trigger: "blur"
          },
          { min: 3, message: "Description is too short!" }
        ],
        DevID: [
          {
            required: true,
            message: "Please enter Device ID",
            trigger: "blur"
          },
          { min: 3, message: "Device ID is too short!" }
        ], 
        name: [
          {
            required: true,
            message: "Please enter  Event Name",
            trigger: "blur"
          },
          { min: 2, message: "Event Name is too short!" }
        ], 
        url: [
          {
            required: true,
            message: "Please enter  URL",
            trigger: "blur"
          },
          { min: 2, message: "URL is too short!" }
        ],   
      },
      newItem: false
    };
  },

  watch: {
    $route(to, from) {  
          
      //this.getUser(to.params.gatewayId);
    }
  },
  created() {
    //alert(this.$route.params.teamId);
    //this.$router.push({ path: `/webhook/${this.$route.params.webhookID}/builder1`}); 
  },

  methods: {
     
    makeTest(){      
      let o = {};
      o.description ="my Description";
      o.event = 'home/base';
      o.url = 'https://single.mesh.com';
      o.requestType = 'post'; //GET, POST, PUT, DELETE
      o.deviceId = '3f002b000000000000000000';
      o.headers = {"Authorization": "Bearer 12345", "X-Application": "My app"};
      o.authentication = false;
      o.auth = {"username": "user@email.com","password": "API_KEY"};
      o.requestFormat = "webform";
      o.query = {"q": "{{{EVENT_VALUE}}}",  "p": "my app"};
      o.form = {"From" : "FROM_PHONE_NUMBER", "To" : "TO_PHONE_NUMBER", "Body" : "{{{BODY}}}"};
      o.json =  {"value": "{{{EVENT_VALUE}}}"};
      o.body =  '{ \"value1\": {{{EVENT_VALUE}}} }'; //JSON as string
      return o;
      //The properties form, json and body are mutually exclusive.
    }, 
    
    loadHook(hook){
       this.userForm.name = hook.event;
       this.userForm.url = hook.url;
       this.userForm.requestType = hook.requestType;
       this.userForm.deviceId = hook.deviceId;
       this.userForm.requestFormat = hook.requestFormat;
       this.userForm.description = hook.description; 
       //load headers:
       let i = 0;
       Object.keys(hook.headers).forEach((key)=> {
          var val = hook.headers[key];         
          this.userForm.headers.push({key: i, header: key,  value: val});
          i++;           
       });
       //load form fields
       i = 0;
       Object.keys(hook.form).forEach((key)=> {
          var val = hook.form[key];         
          this.userForm.fields.push({key: i, header: key,  value: val});
          i++;           
       });
       this.userForm.auth = hook.auth;

       //load query
       i = 0;
       Object.keys(hook.query).forEach((key)=> {
          var val = hook.query[key];         
          this.userForm.query.push({key: i, header: key,  value: val});
          i++;           
       });
      this.userForm.authentication = hook.authentication;

      this.userForm.json = JSON.stringify(hook.json);
      this.userForm.body = hook.body;
    },
    
    deleteHeader(index){      
      this.userForm.headers.splice(index, 1);
    },

    addHeader(){      
      let key = Date.now();
      this.userForm.headers.push({key: key, header: "",  value: ""})
    },

    deleteField(index){      
      this.userForm.fields.splice(index, 1);
    },

    addField(){      
      let key = Date.now();
      this.userForm.fields.push({key: key, header: "",  value: ""})
    },


    deleteQuery(index){      
      this.userForm.query.splice(index, 1);
    },

    addQuery(){      
      let key = Date.now();
      this.userForm.query.push({key: key, header: "",  value: ""})
    },

    menuSelect: function(event) {      
      if (event == "builder") this.showBulder = true;
      else this.showBulder = false; 
    },
    goBack() {      
      this.$router.go(-1);
    },
    getItem(itemId) {
        console.log("getting item " + itemId);
        if (itemId == "new") {
           this.newItem = true;
        //this.userForm.FirstName ="";
        return;
      }
       this.$http        
        .get("/webhook/"+ itemId)
        .then(request => {
          //console.log(request);
          if (request.data.data.length > 0) {
            let o = request.data.data[0].hook_body; 
            this.userForm = JSON.parse(o);                    
          }          
        })
        .catch(() => {
          console.log("auth error");
        });

    },
    
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid)
        {  
           if (this.newItem) 
           this.createHook(formName);
           else
           this.updateHook(formName);
        }
      });
    },

    

    updateHook(formName){      
      let body =  this.userForm;        
      this.$http
        .put("/webhook/"+this.$route.params.webhookID, body)
        .then(request => {
          console.log(request);
          if (request.data.result === "Error") {
            this.res_Error = "Error: " + request.data.error;
            return;
          }
          this.$message({
            type: "success",
            message: "A Hook has been updated."
          });
          //this.$router.push("/users");
        })
        .catch(() => {
          console.log("auth error");
        });
    },

    createHook(formName){      
      let body =  this.userForm;        
      this.$http
        .post("/webhook", body)
        .then(request => {
          console.log(request);
          if (request.data.result === "Error") {
            this.res_Error = "Error: " + request.data.error;
            return;
          }
          this.$message({
            type: "success",
            message: "A Hook has been created."
          });
          this.$router.push("/webhooks");
        })
        .catch(() => {
          console.log("auth error");
        });
    },

    
    deleteClick() {
      this.$confirm(
        "This will permanently delete the Webhook. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {  
          this.$http
            .delete("/webhook/"+ this.$route.params.webhookID)
            .then(request => {
              console.log(request);
              if (request.data.result === "Error") {
                this.res_Error = "Error: " + request.data.error;
                return;
              }
              this.$message({
                type: "success",
                message: "Delete completed"
              });
              this.$router.push("/webhooks");
            })
            .catch(() => {
              console.log("auth error");
            });          
          this.$router.push("/webhooks");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    },  


  },   

  mounted() {
    this.getItem(this.$route.params.webhookID);
    //let o = this.makeTest();
    //console.log(o);
    //this.loadHook(o);   
  },
  computed: {
    hookAsJSON:{
      get(){
          return JSON.stringify(this.userForm, undefined, 2);
      },
      set(val){
         this.userForm = JSON.parse(val);
      }
    }
  },
  components: {
    PrismEditor
  }
};
</script>