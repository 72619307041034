<template>
  <div>  
      <div style="max-width: 650px;"> 
        <el-alert v-show="res_Error.length > 0" :title="res_Error" type="error" show-icon> </el-alert>
         
         


      <el-form ref="userForm"  v-if="newItem" :model="userForm"  status-icon :rules="rules_new"  label-width="120px" size="mini" key="new-form">
          <h3 class="title">New Device</h3>

          <el-form-item label="Core ID" prop="core_id">            
            <el-input v-model="userForm.core_id"></el-input>
          </el-form-item>       
 
          <el-form-item label="Device Name" prop="name">
            <el-input v-model="userForm.name" ></el-input>
          </el-form-item>

          <el-form-item  label="Description" prop="description">
            <el-input v-model="userForm.description"  auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item label="Device Key" prop="key">
            <el-input v-model="userForm.key" v-mask="{mask: '## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ##', autoUnmask: true}"></el-input>
          </el-form-item> 

          <hr>

          <el-form-item  label="Channel 0" prop="channels.0">
            <el-input v-model="userForm.channels['0']"  auto-complete="off"></el-input>
          </el-form-item> 

          <el-form-item  label="Channel 1" prop="channels.1">
            <el-input v-model="userForm.channels['1']"  auto-complete="off"></el-input>
          </el-form-item>      
          
          <el-form-item  label="Channel 2" prop="channels.2">
            <el-input v-model="userForm.channels['2']"  auto-complete="off"></el-input>
          </el-form-item>  

          <el-form-item  label="Channel 3" prop="channels.3">
            <el-input v-model="userForm.channels['3']"  auto-complete="off"></el-input>
          </el-form-item> 

          <el-form-item  label="Channel 4" prop="channels.4">
            <el-input v-model="userForm.channels['4']"  auto-complete="off"></el-input>
          </el-form-item>   

          <el-form-item  label="Channel 5" prop="channels.5">
            <el-input v-model="userForm.channels['5']"  auto-complete="off"></el-input>
          </el-form-item> 

          <el-form-item  label="Channel 6" prop="channels.6">
            <el-input v-model="userForm.channels['6']"  auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item  label="Channel 7" prop="channels.7">
            <el-input v-model="userForm.channels['7']"  auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item  label="Channel 8" prop="channels.8">
            <el-input v-model="userForm.channels['8']"  auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item  label="Channel 9" prop="channels.9">
            <el-input v-model="userForm.channels['9']"  auto-complete="off"></el-input>
          </el-form-item> 

          
          <el-form-item>
             <el-button type="primary" @click="submitNewItem('userForm')">Submit</el-button>
             <el-button @click="goBack">Cancel</el-button>
          </el-form-item>
      </el-form>

      <el-form ref="userForm"  v-else :model="userForm"  status-icon :rules="rules_item"  label-width="120px" size="mini" key="user-form">
           <div> <h3 class="title">Channels</h3>
          </div>          

        <!--  <el-form-item label="Device Name" prop="name">
            <el-input v-model="userForm.name" ></el-input>
          </el-form-item>

          <el-form-item  label="Description" prop="description">
            <el-input v-model="userForm.description"  auto-complete="off"></el-input>
          </el-form-item>   


          <el-form-item label="Device Key" prop="key">
            <el-input v-model="userForm.key" v-mask="{mask: '## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ##', autoUnmask: true}"></el-input>
          </el-form-item>  

          <hr> -->

          <el-form-item  label="Channel 0" prop="channels.0">
            <el-input v-model="userForm.channels['0']"  auto-complete="off"></el-input>
          </el-form-item> 

          <el-form-item  label="Channel 1" prop="channels.1">
            <el-input v-model="userForm.channels['1']"  auto-complete="off"></el-input>
          </el-form-item>      
          
          <el-form-item  label="Channel 2" prop="channels.2">
            <el-input v-model="userForm.channels['2']"  auto-complete="off"></el-input>
          </el-form-item>  

          <el-form-item  label="Channel 3" prop="channels.3">
            <el-input v-model="userForm.channels['3']"  auto-complete="off"></el-input>
          </el-form-item> 

          <el-form-item  label="Channel 4" prop="channels.4">
            <el-input v-model="userForm.channels['4']"  auto-complete="off"></el-input>
          </el-form-item>   

          <el-form-item  label="Channel 5" prop="channels.5">
            <el-input v-model="userForm.channels['5']"  auto-complete="off"></el-input>
          </el-form-item> 

          <el-form-item  label="Channel 6" prop="channels.6">
            <el-input v-model="userForm.channels['6']"  auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item  label="Channel 7" prop="channels.7">
            <el-input v-model="userForm.channels['7']"  auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item  label="Channel 8" prop="channels.8">
            <el-input v-model="userForm.channels['8']"  auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item  label="Channel 9" prop="channels.9">
            <el-input v-model="userForm.channels['9']"  auto-complete="off"></el-input>
          </el-form-item> 

           <el-form-item>        
             <el-button type="primary" @click="submitForm('userForm')">Submit</el-button>             
             <el-button @click="goBack">Back</el-button>
             
          </el-form-item>
      </el-form>
      <router-view></router-view>
      </div>      
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    var validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the User Name"));
      } else {
        callback();
      }
    };

    return {
      res_Error: "",
      activeIndex2: '1',
      userForm: {
        id: 0,
        Description: "",
        GW_id: "",
        Name: "",
        channels: {},               
      },
      
      rules_new: {
        Description: [
          {
            required: true,
            message: "Please enter Description",
            trigger: "blur"
          },
          { min: 6, message: "Description is too short!" }
        ],
        core_id: [
          {
            required: true,
            message: "Please enter  Core ID",
            trigger: "blur"
          },
          { min: 3, message: "Core ID is too short!" }
        ], 
        
        name: [
          {
            required: true,
            message: "Please enter  Device Name",
            trigger: "blur"
          },
          { min: 3, message: "Device Name is too short!" }
        ],     
    },
    rules_item: {
        Description: [
          {
            required: true,
            message: "Please enter Description",
            trigger: "blur"
          },
          { min: 6, message: "Description is too short!" }
        ],
        DevID: [
          {
            required: true,
            message: "Please enter Device ID",
            trigger: "blur"
          },
          { min: 3, message: "Device ID is too short!" }
        ], 
        name: [
          {
            required: true,
            message: "Please enter  Device Name",
            trigger: "blur"
          },
          { min: 3, message: "Device Name is too short!" }
        ],     
    },

      newItem: false
    };
  },

  watch: {
    $route(to, from) {      
      //this.getUser(to.params.gatewayId);
    }
  },
  created() {
    //alert(this.$route.params.teamId);
  },

  methods: {

    menuSelect: function(event) {
      console.log(event);
      //this.$router.push(event);
      this.$router.push({ path: `/device/${this.$route.params.devID}/${event}`}); 
    },

    goBack() {      
      this.$router.go(-1);
    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid)  this.updateItem(formName)
      });
    },

    updateItem(formName){     
      
      console.log("channels");console.log( JSON.stringify(this.userForm.channels)); 
      let attributes = {};
      //remove empty channels:
      Object.keys(this.userForm.channels).forEach((k)=>{          
          if (this.userForm.channels[k] == "")
          {
            delete this.userForm.channels[k];
          }
      });        

      attributes.channels = this.userForm.channels; 
      let body = {        
          id: this.userForm.id, 
          core_id:  this.userForm.core_id,         
          name: this.userForm.name,
          description: this.userForm.description,
          key:  this.userForm.key,
          body: JSON.stringify(attributes),                 
         };

      this.$http
        .put("/devices", body)
        .then(request => {
          console.log(request);
          if (request.data.result === "Error") {
            this.res_Error = "Error: " + request.data.error;
            return;
          }
          this.$message({
            type: "success",
            message: "A Device has been updated."
          });
          //this.$router.push("/users");
        })
        .catch(() => {
          console.log("auth error");
        });
    },


    submitNewItem(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) this.addItem();
      });
    },   
    

    addItem() {
      
      let attributes = {};
      //remove empty channels:
      Object.keys(this.userForm.channels).forEach((k)=>{          
          if (this.userForm.channels[k] == "")
          {
            delete this.userForm.channels[k];
          }
      });        

      attributes.channels = this.userForm.channels; 

      let body = {                 
          core_id: this.userForm.core_id,
          name: this.userForm.name,
          description: this.userForm.description,
          user_id: this.user_profile.id,      // for now only! 
          key: this.userForm.key,
          body: JSON.stringify(attributes),         
      };
      this.$http
        .post("/devices", body)
        .then(request => {
          console.log(request);
          if (request.data.result === "Error") {
            this.res_Error = "Error: " + request.data.error;
            return;
          }
          this.$message({
            type: "success",
            message: "A New Device has been created."
          });
          this.$router.push("/devices");
        })
        .catch(() => {
          console.log("auth error");
        });
    },

    getItem(itemId) {
      if (itemId == "new") {
        this.newItem = true;
        //this.userForm.FirstName ="";
        return;
      }
      console.log("getting item" + itemId);
      let command = {
        command: "get_item",
        params: {
          id: itemId
        }
      };
      this.$http        
        .get("/devices/"+ itemId)
        .then(request => {
          console.log(request);
          if (request.data.data.length > 0) {
            this.userForm = request.data.data[0];
            if (request.data.data[0].body)
            {
              let attributes =  JSON.parse(request.data.data[0].body);
              this.userForm.channels = attributes.channels;
            }
            else
              this.userForm.channels = {};           
          }
          //this.form = request.data.result;
        })
        .catch(() => {
          console.log("auth error");
        });
    },

    

    deleteClick() {
      this.$confirm(
        "This will permanently delete the Device. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let command = {
            command: "delete",
            params: {
              id: this.userForm.id,              
            }
          };
          this.$http            
            .delete("/devices/"+ this.userForm.id)
            .then(request => {
              console.log(request);
              if (request.data.result === "Error") {
                this.res_Error = "Error: " + request.data.error;
                return;
              }
              this.$message({
                type: "success",
                message: "Delete completed"
              });
              this.$router.push("/devices");
            })
            .catch(() => {
              console.log("auth error");
            });          
          this.$router.push("/devices");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    }
  },

  mounted() {
    this.getItem(this.$route.params.devID);
  },
  computed: mapState(["user_profile"])
};
</script>