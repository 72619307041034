<template>
 <div style="max-width: 800px;">
     <div>
       <div style="display: inline-block;">
         <h4>Webhooks</h4>
      </div>
      <div style="display: inline-block;float: left;">
         <el-button type="primary" v-on:click="addItem">New</el-button>
      </div>  
    </div>
    <el-table
    ref="usersTable"
    :data="tableData"    
    border    
    highlight-current-row
    
    @sort-change="onSort"
    style="width: 100%">

<el-table-column
      property="event"
      label="Event name"
      sortable
      >
      <template slot-scope="scope">
        <router-link :to="{name: 'Webhook', params:{webhookID: scope.row.id}}">{{ scope.row.event }}</router-link>
      </template>
    </el-table-column> 

    <el-table-column
      property="description"
      label="Description"
      >      
    </el-table-column>   

     

    <el-table-column
      property="device_id"
      label="Device ID"
      sortable
      width="200">
       <template slot-scope="scope">
         <span v-if="scope.row.device_id != null && scope.row.device_id != ''">{{scope.row.device_id}}</span>  
         <span v-else >any</span>        
      </template>
    </el-table-column>    

    </el-table>

      
     <el-pagination
     background
     @current-change="pageChange" 
     layout="prev, pager, next"
     :current-page=currentPage 
     :page-size=pageSize 
     :total=total>
    </el-pagination>


 </div>    
</template>

<script>
  import { mapState, mapMutations } from "vuex"; // Add mapMutations
  export default {
    watch: {
    $route(to, from) {
        this.restoreParams();
     }
    }, 
  data() {
    return {
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      firstRow: 0,
      sortField: null,
      sortOrder: 0,
      currentRow: null
      }
    },

    mounted() {
    this.restoreParams();
    },

     computed: {
        localComputed() {},
        ...mapState(["devices_table"])
    },

     methods: {
       restoreParams() {
         let page = this.devices_table.page;
         this.firstRow = this.pageSize * (page - 1);
         this.$refs.usersTable.sort(
         this.devices_table.sortField,
         this.devices_table.sortOrder
        );      
        this.getItems();
      },
       getItems() {
        let params = {
            page : this.devices_table.page,
            pageSize : this.pageSize,
            sortField: this.sortField,
            sortOrder: this.sortOrder
         };
         this.$http
        .patch("/webhooks", params)
        .then(request => {
          console.log(request);
          this.tableData = request.data.data.data;
          this.total = request.data.data.total;
          this.currentPage = this.devices_table.page;
        })
        .catch(error => {
          console.log("auth error");
          console.log(error);
        });
      },
      addItem(){          
         this.$router.push("/webhook/new");
      },
      onSort(){},
      pageChange(){},


     }
  }
</script>  
