<template>
  <div>
      <el-row></el-row>
      <el-row>
      <div style="display: inline-block;float: right;">                 
              <el-button-group>                  
                  <el-button type="danger" icon="el-icon-delete"  @click="rebootClick('scriptForm')">Reboot</el-button>
          </el-button-group>
          </div>
      </el-row>
  </div>
</template>

<script>
export default {
    data() {   
    return {           
    };
  },
  methods: {
     
     rebootClick() {
        this.$confirm(
        "This will reboot Device! . Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {  
          let encoded = encodeURIComponent(this.currentFile);    
          this.$http            
            //.delete("device/"+this.$route.params.devID+"/files/"+ this.currentFile)   
            .get("device/"+this.$route.params.devID+"/command/reboot")          
            .then(request => {
             console.log(request.data.data);
                 if (request.data.data.result != "OK") {
                 this.$message({
                   type: "error",
                   message: "Reboot error"
                 });    
                 //this.res_Error = "Error: " + request.data.error;
                 return;
             }
              this.$message({
                type: "success",
                message: "Reboot completed"
              });
              //this.getItems();
            })
            .catch(() => {
              console.log("auth error");
            });          
          //this.$router.push({ path: `/device/${this.$route.params.devID}/script`});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Reboot canceled"
          });
        });
    },    
  }
}
</script>

