<template>
  <div>  
      <div style="max-width: 450px;"> 
        <el-alert v-show="res_Error.length > 0" :title="res_Error" type="error" show-icon> </el-alert>
      <el-form ref="userForm"  v-if="newUser" :model="userForm"  status-icon :rules="rules_new"  label-width="120px" size="mini" key="new-form">
          <h3 class="title">New User</h3> 
          <el-form-item  label="User name" prop="username">
            <el-input v-model="userForm.username"  auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item label="First name" prop="firstname">
            <el-input v-model="userForm.firstname" auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item label="Last name" prop="lastname">
            <el-input v-model="userForm.lastname" auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item label="Email">
            <el-input v-model="userForm.email"></el-input>
          </el-form-item>
          <el-form-item  label="Password" prop="password">
            <el-input type="password" v-model="userForm.password" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item>
             <el-button type="primary" @click="submitNewUser('userForm')">Submit</el-button>
             <el-button @click="goBack">Cancel</el-button>
          </el-form-item>
      </el-form>

      <el-form ref="userForm"  v-else :model="userForm"  status-icon :rules="rules3"  label-width="120px" size="mini" key="user-form">
          <div>
                <div style="display: inline-block"><h3 class="title">User: {{userForm.username}}</h3>
                </div> <div style="display: inline-block;float: right;">
                  <el-button v-if="user_profile.username=='admin' && userForm.username !='admin'"   type="danger" @click="deleteClick('userForm')">
                    <font-awesome-icon icon="trash-alt" size="1x"/>
                    Delete
                    </el-button>
                </div>
          </div>          
          <el-form-item label="First name" prop="firstname">
            <el-input v-model="userForm.firstname" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="Last name">
            <el-input v-model="userForm.lastname"></el-input>
          </el-form-item>
          <el-form-item label="Email">
            <el-input v-model="userForm.email"></el-input>
          </el-form-item>  
           <el-form-item>        
             <el-button type="primary" @click="submitForm('userForm')">Submit</el-button>
             <el-button type="warning" @click="changePassword()">Change Password</el-button>
             <el-button @click="goBack">Back</el-button>
             
          </el-form-item>
      </el-form>
      <router-view></router-view>
      </div>      
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    var validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the User Name"));
      } else {
        callback();
      }
    };

    return {
      res_Error: "",
      userForm: {
        id: 0,
        FirstName: "",
        LastName: "",
        UserName: "",
        password: "",
        Email: ""
      },
      rules3: {
        password: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur"
          },
          { min: 6, message: "Your password is too short!" }
        ],
        FirstName: [
          {
            required: true,
            message: "Please enter first name",
            trigger: "blur"
          },
          { min: 3, message: "First name is too short!" }
        ]
      },
      rules_new: {
        LastName1: [
          {
            required: true,
            message: "Please enter last name",
            trigger: "blur"
          },
          { min: 6, message: "Last name is too short!" }
        ],
        username: [
          {
            required: true,
            message: "Please enter user name",
            trigger: "blur"
          },
          { min: 3, message: "User name is too short!" }
        ],
        password: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur"
          },
          { min: 6, message: "Your password is too short!" }
        ],
        firstname: [
          {
            required: true,
            message: "Please enter first name",
            trigger: "blur"
          },
          { min: 3, message: "First name is too short!" }
        ]
      },

      newUser: false
    };
  },

  watch: {
    $route(to, from) {
      //alert(to.params.userId);
      this.getUser(to.params.userId);
    }
  },
  created() {
    //alert(this.$route.params.teamId);
  },

  methods: {
    goBack() {      
      this.$router.go(-1);
    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid)  this.updateUser(formName)
      });
    },

    updateUser(formName){
       let body = {        
          id: this.userForm.id,           
          firstname: this.userForm.firstname,
          lastname: this.userForm.lastname,          
          email: this.userForm.email        
         };
      this.$http
        .put("/users", body)
        .then(request => {
          console.log(request);
          if (request.data.result === "Error") {
            this.res_Error = "Error: " + request.data.error;
            return;
          }
          this.$message({
            type: "success",
            message: "A User has been updated."
          });
          //this.$router.push("/users");
        })
        .catch(() => {
          console.log("auth error");
        });
    },


    submitNewUser(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) this.addUser();
      });
    },

    addUser() {
      //alert("Add User");
      let body = {                 
          username: this.userForm.username,
          firstname: this.userForm.firstname,
          lastname: this.userForm.lastname,
          password: this.userForm.password,
          email: this.userForm.email        
      };
      this.$http
        .post("/users", body)
        .then(request => {
          console.log(request);
          if (request.data.result === "Error") {
            this.res_Error = "Error: " + request.data.error;
            return;
          }
          this.$message({
            type: "success",
            message: "A New User has been created."
          });
          this.$router.push("/users");
        })
        .catch(() => {
          console.log("auth error");
        });
    },

    getUser(userId) {
      if (userId == "new") {
        this.newUser = true;
        //this.userForm.FirstName ="";
        return;
      }
      console.log("getting user" + userId);
      let command = {
        command: "profile",
        params: {
          id: userId
        }
      };
      this.$http
        .get("/users/"+ userId)
        .then(request => {
          console.log(request);
          if (request.data.data.length > 0) {
            this.userForm = request.data.data[0];
          }
          //this.form = request.data.result;
        })
        .catch(() => {
          console.log("auth error");
        });
    },

    changePassword(){ 
      this.$router.push({ path: `/users/${this.userForm.id}/password`}); 
    },

    deleteClick() {
      this.$confirm(
        "This will permanently delete the user. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let command = {
            command: "delete",
            params: {
              id: this.userForm.id,              
            }
          };
          this.$http
            .delete("/users/"+ this.userForm.id)
            .then(request => {
              console.log(request);
              if (request.data.result === "Error") {
                this.res_Error = "Error: " + request.data.error;
                return;
              }
              this.$message({
                type: "success",
                message: "Delete completed"
              });
              this.$router.push("/users");
            })
            .catch(() => {
              console.log("auth error");
            });          
          this.$router.push("/users");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    }
  },

  mounted() {
    this.getUser(this.$route.params.userId);
  },
  computed: mapState(["user_profile"])
};
</script>