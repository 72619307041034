<template>
  <div>     
      <el-row>
      </el-row>  
      <el-row>
           <el-col :span="8"><div class="grid-content bg-purple1">
            <div>
       <div style="display: inline-block;">
         <h4>Scripts</h4>
      </div>
      <div style="display: inline-block;float: left;">
         <el-button type="primary" v-on:click="addScript">
           <font-awesome-icon icon="user-plus" size="1x"/>
           New
           </el-button>
      </div> 
    </div>       
            <el-table
               ref="usersTable"
               :data="scriptsData"               
               border    
               highlight-current-row               
               @sort-change="onSort"
               style="width: 100%">

               <el-table-column
                property="name"
                label="Name"
                sortable
                width="100">
                 <template slot-scope="scope">
                    <router-link :to="{name: 'Script', params:{devID: scope.row.core_id, scriptID: scope.row.id}}">{{ scope.row.name }}</router-link>
                 </template>
                </el-table-column>

                <el-table-column
                property="description"
                label="Description"
                sortable
                >
                </el-table-column>

            </el-table>   
               
               
            </div></el-col>
           <el-col :span="12"><div class="grid-content bg-purple-light1"></div></el-col>
      </el-row> 
  </div>  
</template>

<script>
import { mapState, mapMutations } from "vuex"; // Add mapMutations
export default {
  data() {   
    return {  
      scriptsData: [],
      total: 0,
      pageSize: 5,
      currentPage: 1,
      firstRow: 0,
      sortField: null,
      sortOrder: 0,
      currentRow: null,    
      newItem: false
    };
  },

  methods: {
    addScript() {      
      //this.$router.push("/users/new");
      this.$router.push({ path: `/device/${this.$route.params.devID}/script/new`});
    },
    onSort(event) {
     /* this.sortField = event.prop;
      if (event.order == "ascending") this.sortOrder = 1;
      else this.sortOrder = -1;

      console.log("sort");
      console.log(event);

      this.set_devices_table_sort({
        sortField: event.prop,
        sortOrder: event.order
      }); // save sort in store..
      this.getItems(); */
    },

    getItems() {      
      this.$http        
        .get("/scripts", {params: {core_id: this.$route.params.devID}})
        .then(request => {
          console.log(request);
          this.scriptsData = request.data.data;          
        })
        .catch(error => {
          console.log("auth error");
          console.log(error);
        });
    },
  },

  mounted() {
    this.getItems();
  },

};

</script>