export function requireAuth(to, from, next) {
    if (!isLoggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  }

  export function isLoggedIn() {    
    //const idToken = getIdToken();
    //return !!idToken && !isTokenExpired(idToken);
    const idToken = getAccessToken();
    console.log(idToken);
    if (idToken == null) return false;  
    return true;
  }

// Get and store access_token in local storage
export function setAccessToken(accessToken) { 
  localStorage.setItem('ACCESS_TOKEN_KEY', accessToken);
}

export function getAccessToken() {
  let _s = localStorage.getItem('ACCESS_TOKEN_KEY');
  if (_s == null) return null;
  //return _s;
  return JSON.parse(_s);
}

export function logout() {  
  clearAccessToken();
  //router.go('/');
}

function clearAccessToken() {
  localStorage.removeItem('ACCESS_TOKEN_KEY');
}



  