<template>
  <div>     
     <el-row :gutter="20">
         <el-col :span="6"><div class="grid-content bg-purple1"> 
           <div style="display: inline-block;">
                <h4>Firmware</h4>      
           </div>
            
           <el-row>
               <div style="display: inline-block;float: right;">
                    <el-button-group>                  
                        <el-button type="success" icon="el-icon-upload" plain @click="uploadVersion()">upload</el-button>
                        <el-button type="danger" icon="el-icon-delete"  :disabled="filecurrentRow===null" plain @click="deleteClick('scriptForm')">delete</el-button>
                    </el-button-group>
               </div>
          </el-row> 
           <el-table
               ref="filesTable"
               max-height="400" 
               :data="filesData"               
               border    
               highlight-current-row
               @current-change="handleCurrentChange"
               style="width: 100%">               

               <el-table-column
                property="path"
                label="Version"
                sortable
                >                 
                </el-table-column> 

                <el-table-column
                property="description"
                label="Description"
                sortable
                >                 
                </el-table-column> 
                
            </el-table>   

         </div>
         </el-col>
         <el-col :span="18"><div class="grid-content bg-purple1"> 
             <div style="display: inline-block;">
                <h4>Devices</h4>      
             </div>

        <el-row>
               <div style="display: inline-block;float: right;">                
                   <span  v-if="filecurrentRow === null" style="margin-right:50px;" >Select Version ..</span>  
                   <span v-else  style="margin-right:50px;"> New Version: {{filecurrentRow.path}}</span>
                    <el-button-group>                  
                        <el-button type="success" icon="el-icon-upload" :disabled="filecurrentRow===null || multipleSelection.length < 1" plain @click="applyFW()">Apply</el-button>                        
                    </el-button-group>
               </div>
        </el-row> 



        <el-table
          ref="devicesTable"
          :data="tableData"
          @selection-change="handleSelectionChange">
          border    
          highlight-current-row
    
    
    style="width: 100%">

    <el-table-column
                type="selection"
                width="55">
    </el-table-column>

    <el-table-column
      property="core_id"
      label="Core ID"
      sortable
      width="100"
      >
      <template slot-scope="scope">
        <router-link :to="{name: 'Device', params:{devID: scope.row.id}}">{{ scope.row.core_id }}</router-link>
      </template>
    </el-table-column>


    <el-table-column
      property="name"
      label="Name"
      sortable
      width="100">
    </el-table-column>

    <el-table-column
      property="description"
      label="Description"
      sortable
      width="200">
    </el-table-column>

    <el-table-column label="Online">
      <template slot-scope="scope">  
         <i :class="scope.row.online == false ? 'el-icon-error my-gray' : 'el-icon-success my-green'"></i>
      </template>
    </el-table-column>  

    <el-table-column
      property="ver"
      label="Current Version"    
      >
    </el-table-column> 

     <el-table-column
      property="pending"
      label="Pending Version"    
      >
    </el-table-column> 
    

    </el-table>
         </div>
         </el-col>

     </el-row>
     <!--{{multipleSelection}}-->
     <!--{{tableData}}-->
  </div>
</template>
          


<script>
  export default {
    data() {
         return {
            filesData: [],
            filecurrentRow: null,
            multipleSelection: [],
            tableData: [],
            total: 0,
            pageSize: 10,
            currentPage: 1,
            firstRow: 0,
            sortField: null,
            sortOrder: 0,
            currentRow: null,
            multipleSelection: []
       }
      },
    methods: {      
      getItems()  {        
      let params = {
        page : 1,
        pageSize : 1000,
        sortField: this.sortField,
        sortOrder: this.sortOrder
      };      
      this.$http
        .patch("/devices", params)
        .then(request => {          
          console.log(request);
          //this.tableData = request.data.data.data;          
          this.$http
           .get("/fw/pending")
           .then(request_pending => {
              //console.log(request_pending.data.data);
              //inject pending
              for(let i=0; i< request.data.data.data.length; i++)
              {                  
                  if (request.data.data.data[i].ver != request_pending.data.data[request.data.data.data[i].core_id])
                      request.data.data.data[i].pending = request_pending.data.data[request.data.data.data[i].core_id]; 
              } 
              this.tableData = request.data.data.data;
           })         
        })
        .catch(error => {
          console.log("auth error");
          console.log(error);
        });
      },
      getFWVersions(){
        this.$http
        .get("/fw")
        .then(request => {
          console.log(request);
          this.filesData = request.data.data;
          //this.total = request.data.data.total;
          //this.currentPage = this.devices_table.page;
        })
        .catch(error => {
          console.log("auth error");
          console.log(error);
        });
      },
      handleCurrentChange(val) {
        this.filecurrentRow = val;
      },
      uploadVersion(){
         this.$router.push("/ota/upload");
      },

      applyFW(){

         this.$confirm(
        "Do you wand to apply the Version "+ this.filecurrentRow.path+" to selected devices?",
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning"
        }
        )
        .then(() => {
         

          let selectionList = "";
          for(let i= 0; i < this.multipleSelection.length; i++)
          {
            if(selectionList == "")
            selectionList+= this.multipleSelection[i].core_id;
            else
            selectionList+= ","+this.multipleSelection[i].core_id;
          }
          //alert(selectionList);
          let params = {
             fw_version : this.filecurrentRow.path,
             applyList : selectionList,             
          };
           this.$http
           .post("/fw/apply", params)
           .then(request => {
               this.$refs.devicesTable.clearSelection();
               this.$message({
                type: "success",
                message: "Version "+this.filecurrentRow.path+" has been applied."
              });
              this.getItems();//refresh
            })
           .catch(error => {
               console.log("auth error");
               console.log(error);
          }); 
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Apply canceled"
          });
        });     
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      deleteClick() {
      this.$confirm(
        "This will permanently delete the Version "+ this.filecurrentRow.path+". Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {          
          this.$http
            .delete("/fw/"+ this.filecurrentRow.path)
            .then(request => {
              console.log(request);
              if (request.data.result === "Error") {
                this.res_Error = "Error: " + request.data.error;
                return;
              }
              this.$message({
                type: "success",
                message: "Delete completed"
              });
              this.getFWVersions();
              //this.$router.push("/users");
            })
            .catch(() => {
              console.log("auth error");
            });          
          //this.$router.push("/users");
          this.getFWVersions();
          this.getItems();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    }  
    },
    mounted() {
      this.getItems();
      this.getFWVersions();
    },  
  }
</script>      