// store.js

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// the root, initial state object
const state = {
  count: 0,
  user_profile: {
    id: 0,
    UserName: '',
    FirstName: '',
  },
  //persist table params
  users_table: {
     page: 1,
     sortField: null,
     sortOrder: null,
  },

  //persist table params
  gateways_table: {
    page: 1,
    sortField: null,
    sortOrder: null,
 },

  //persist table params
devices_table: {
  page: 1,
  sortField: null,
  sortOrder: null,
},

 socket: {
  isConnected: false,
  message: '',
  reconnectError: false,
  gw_list: [],
}
  

}

// define the possible mutations that can be applied to our state
const mutations = {
  increment: state => state.count++,
  decrement: state => state.count--,
  set_value: (state, val)=> state.count = val,
  set_profile: (state, val) => state.user_profile = val,
  set_users_table_page: (state, val)=>state.users_table.page = val,
  set_users_table_sort: (state, val)=>{
     state.users_table.sortField = val.sortField;
     state.users_table.sortOrder = val.sortOrder;
  },
  
  set_gateways_table_page: (state, val)=>state.gateways_table.page = val,
  set_gateways_table_sort: (state, val)=>{
     state.gateways_table.sortField = val.sortField;
     state.gateways_table.sortOrder = val.sortOrder;
  },

  set_devices_table_page: (state, val)=>state.devices_table.page = val,
  set_devices_table_sort: (state, val)=>{
     state.devices_table.sortField = val.sortField;
     state.devices_table.sortOrder = val.sortOrder;
  },

  set_webhook_table_page: (state, val)=>state.devices_table.page = val,
  set_webhook_table_sort: (state, val)=>{
     state.webhook_table.sortField = val.sortField;
     state.webhook_table.sortOrder = val.sortOrder;
  },
     
  SOCKET_ONOPEN (state, event)  {
    state.socket.isConnected = true;
  },
  SOCKET_ONCLOSE (state, event)  {
    state.socket.isConnected = false
  },
  SOCKET_ONERROR (state, event)  {
    console.error(state, event)
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE (state, message)  {
    state.socket.message = message.data;
    let x = JSON.parse(message.data);
    if (x.type == 'gw_list')
    {
      state.socket.gw_list = x.data;
    }
    console.log(message);
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT(state, count) {
    console.info(state, count)
  },
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  },

  
}

// create the Vuex instance by combining the state and mutations objects
// then export the Vuex store for use by our components
export default new Vuex.Store({
  state,
  mutations
})