<template>
  <div>
      <el-row>
          <el-alert v-show="res_Error.length > 0" :title="res_Error" type="error" show-icon> </el-alert>
      </el-row>  
      <el-row :gutter="20">
           <el-col :span="8"><div class="grid-content bg-purple1">
            <div>
       <div style="display: inline-block;">
         <h4>Files</h4>
      </div>
      
    </div>       
            <el-table
               ref="filesTable"
               max-height="400" 
               :data="filesData"               
               border    
               highlight-current-row
               @current-change="handleSelectionChange" 
               style="width: 100%">

               <el-table-column
                property="path"
                label="Path"
                sortable
                >                 
                </el-table-column> 
                <el-table-column
                property="size"
                label="Size"                
                >
                </el-table-column>
            </el-table>   
               
               
            </div></el-col>
           <el-col :span="16">
            <div class="grid-content bg-purple-ligh1">
                <div style="display: inline-block;float: right;">                 
              <el-button-group>                  
                  <el-button type="danger" icon="el-icon-delete" plain @click="deleteClick('scriptForm')" :disabled="currentFile == ''">delete</el-button>
          </el-button-group>
          </div>
                <prism-editor v-model="fileText" language="js" :line-numbers="lineNumbers" class="my-editor" /> 
            </div></el-col>
      </el-row> 
</div>
</template>


<script>
import PrismEditor from "vue-prism-editor";
export default {
  data() {   
    return { 
      res_Error: "",
      lineNumbers: false, 
      filesData: [],
      fileText: "", 
      currentFile: ""     
    };
  },
  methods: {

    handleSelectionChange(val){
        console.log('handleSelectionChange');
        console.log(val.path);
        this.getFile(val.path);
    },

    getFile(path){
        this.currentFile = "";
        this.$http        
        .get("/file", {params: {core_id: this.$route.params.devID, path: path}})
        .then(request => {
          console.log(request.data.data);
           if (!request.data.data.ok) {
            this.res_Error = "Error: " + request.data.data.error;
            return;
          }
          console.log(request.data.data.return_value);
          this.fileText = request.data.data.return_value;
          this.currentFile = path;
        })
        .catch(error => {
          console.log("auth error");
          console.log(error);
        });
    },

    getItems() { 
      this.filesData = [];       
      this.$http        
        .get("/files", {params: {core_id: this.$route.params.devID}})
        .then(request => {
          console.log(request.data.data);
           if (!request.data.data.ok) {
            this.res_Error = "Error: " + request.data.data.error;
            return;
          }
          console.log(request.data.data.return_value);
          //this.filesData = request.data.data;  
          let arr =  request.data.data.return_value.split(',');  
          
          arr.forEach((element)=> {
               let x = {};
               let a_el = element.split(':');
               x.path = a_el[0].trim();
               x.size = a_el[1];
               this.filesData.push(x);

           });          
        })
        .catch(error => {
          console.log("auth error");
          console.log(error);
        });
    },

    deleteClick() {
        this.$confirm(
        "This will permanently delete the File '"+this.currentFile+"'. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {  
          let encoded = encodeURIComponent(this.currentFile);    
          this.$http            
            //.delete("device/"+this.$route.params.devID+"/files/"+ this.currentFile)   
            .delete("device/"+this.$route.params.devID+"/files/"+ encoded)          
            .then(request => {
              console.log(request.data.data);
                 if (!request.data.data.ok) {
                 this.res_Error = "Error: " + request.data.data.error;
                 return;
             }
              this.$message({
                type: "success",
                message: "Delete completed"
              });
              this.getItems();
            })
            .catch(() => {
              console.log("auth error");
            });          
          //this.$router.push({ path: `/device/${this.$route.params.devID}/script`});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    },    
  },

  mounted() {
    this.getItems();
  },
  components: {
    PrismEditor
  }
}
</script>