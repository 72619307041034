<template>
  <div>
      <el-alert v-show="res_Error.length > 0" :title="res_Error" type="error" show-icon> </el-alert>
      <el-form ref="scriptForm"  v-if="newScript" :model="scriptForm"  status-icon :rules="rules_new"  label-width="120px" size="mini" key="new-form">
          <h3 class="title">New Script</h3> 
          <el-form-item  label="Name" prop="name">
            <el-input v-model="scriptForm.name"  auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item label="Description" prop="description">
            <el-input v-model="scriptForm.description" auto-complete="off"></el-input>
          </el-form-item>
          
          <el-form-item>
             <el-button type="primary" @click="submitNewScript('scriptForm')">Submit</el-button>
             <el-button @click="goBack">Cancel</el-button>
          </el-form-item>
      </el-form>

      <div v-else>         
         <div style="display: inline-block"><h3 class="title">Script: {{scriptForm.name}}</h3>
         </div>        
           
          <el-row>
            <el-col :span="8">  
            <el-form ref="scriptForm"   :model="scriptForm"  status-icon :rules="rules_new"  label-width="120px" size="mini" key="new-form">
              <el-form-item label="Description" prop="description">
               <el-input v-model="scriptForm.description" auto-complete="off" ></el-input>
              </el-form-item> 
          </el-form>
          </el-col>
           <el-col :span="16">
             <div style="display: inline-block;float: right;">                 
              <el-button-group>
                  <el-button type="info" icon="el-icon-back" plain @click="goBack">cancel</el-button>
                  <el-button type="primary" icon="el-icon-edit" plain  @click="saveScript('scriptForm')">save</el-button>
                  <el-button type="success" icon="el-icon-upload" plain @click="uploadScript()">upload</el-button>
                  <el-button type="danger" icon="el-icon-delete" plain @click="deleteClick('scriptForm')">delete</el-button>
          </el-button-group>
          </div>
           </el-col> 
          </el-row>
          
          <prism-editor v-model="scriptForm.text" language="js" :line-numbers="lineNumbers" class="my-editor" />   
      </div> 




  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import PrismEditor from "vue-prism-editor";
export default {
 data() {
     return {
         res_Error: "",
         lineNumbers: true,
         code: "console.log('edit me')",
         scriptForm: {
            id: 0,
            name: "",
            description: "",
            text: "",        
      },
      rules_new: {        
        name: [
          {
            required: true,
            message: "Please enter script name",
            trigger: "blur"
          },
          { min: 3, message: "Script name is too short!" }
        ],
       
        
      },
         newScript: false
     }
 },
 methods: {    
    goBack() {      
      this.$router.go(-1);
    },
    submitNewScript(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) this.addScript();
      });
    },

    getScript(itemId) {
      if (itemId == "new") {
        this.newScript = true;        
        return;
      }
      let command = {
        command: "get_item",
        params: {
          id: itemId
        }
      };
      this.$http        
        .get("/scripts/"+ itemId)
        .then(request => {
          console.log(request);
          if (request.data.data.length > 0) {
            this.scriptForm = request.data.data[0]; 
            if (!this.scriptForm.text) this.scriptForm.text = "";                
          }
          //this.form = request.data.result;
        })
        .catch(() => {
          console.log("auth error");
        });
    },


    addScript() {     
      let body = {                 
          name: this.scriptForm.name,
          description: this.scriptForm.description,
          core_id: this.$route.params.devID,            
      };
      this.$http
        .post("/scripts", body)
        .then(request => {
          console.log(request);
          if (request.data.result === "Error") {
            this.res_Error = "Error: " + request.data.error;
            return;
          }
          this.$message({
            type: "success",
            message: "A New Script has been created."
          });         
          this.$router.push({ path: `/device/${this.$route.params.devID}/script`});
        })
        .catch(() => {
          console.log("auth error");
        });
    },

    saveScript(){
      let body = {        
          id: this.scriptForm.id,           
          description: this.scriptForm.description,
          text: this.scriptForm.text,           
         };
      this.$http
        .put("/scripts", body)
        .then(request => {
          console.log(request);
          if (request.data.result === "Error") {
            this.res_Error = "Error: " + request.data.error;
            return;
          }
          this.$message({
            type: "success",
            message: "Script has been updated."
          });
          //this.$router.push("/users");
        })
        .catch(() => {
          console.log("auth error");
        });
    },

    uploadScript(){
      let command = {
            command: "upload",
            params: {
              id: this.scriptForm.id,              
            }
          };
      this.$http
        .patch("/scripts/"+ this.scriptForm.id)
        .then(request => {
          console.log(request);
          if (request.data.result === "Error") {
            this.res_Error = "Error: " + request.data.error;
            return;
          }
          if (request.data.data.ok)
          {
             this.res_Error = "";
             this.$message({
               type: "success",
               message: "Script has been uploaded."
             });
          }
          else {
            this.res_Error = request.data.data.error;
          }

          //this.$router.push("/users");
        })
        .catch(() => {
          console.log("auth error");
        });
    },

    deleteClick() {
      this.$confirm(
        "This will permanently delete the Script. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let command = {
            command: "delete",
            params: {
              id: this.scriptForm.id,              
            }
          };
          this.$http            
            .delete("/scripts/"+ this.scriptForm.id)
            .then(request => {
              console.log(request);
              if (request.data.result === "Error") {
                this.res_Error = "Error: " + request.data.error;
                return;
              }
              this.$message({
                type: "success",
                message: "Delete completed"
              });
              this.$router.push({ path: `/device/${this.$route.params.devID}/script`});
            })
            .catch(() => {
              console.log("auth error");
            });          
          this.$router.push({ path: `/device/${this.$route.params.devID}/script`});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    },
  
 },

 mounted() {
    this.getScript(this.$route.params.scriptID);
  },

 components: {
    PrismEditor
  }
}
</script>

