<template>
<div style="max-width: 800px;">
     <div>
       <div style="display: inline-block;">
         <h4>Devices</h4>
      </div>
      <div style="display: inline-block;float: left;">
         <el-button type="primary" v-on:click="addItem">New</el-button>
      </div>       
      <div style="display: inline-block;float: left; margin-left: 50px;">
         <el-button type="primary" v-on:click="onLog">Logs</el-button>
      </div> 
    </div>
    <el-table
    ref="usersTable"
    :data="tableData"
    :row-class-name="tableRowClassName"
    border    
    highlight-current-row
    @current-change="handleCurrentChange"
    @sort-change="onSort"
    style="width: 100%">

    <!--
    <el-table-column
      property="id"
      label="id"
      sortable
      width="70">
      <template slot-scope="scope">
        <router-link :to="{name: 'Device', params:{devID: scope.row.id}}">{{ scope.row.id }}</router-link>
      </template>
    </el-table-column> -->

    <el-table-column
      property="core_id"
      label="Core ID"
      sortable
      >
      <template slot-scope="scope">
        <router-link :to="{name: 'Device', params:{devID: scope.row.id}}">{{ scope.row.core_id }}</router-link>
      </template>
    </el-table-column>


    <el-table-column
      property="name"
      label="Name"
      sortable
      width="100">
    </el-table-column>

    <el-table-column
      property="description"
      label="Description"
      sortable
      width="200">
    </el-table-column>

    <el-table-column label="Online">
      <template slot-scope="scope">  
         <i :class="scope.row.online == false ? 'el-icon-error my-gray' : 'el-icon-success my-green'"></i>
      </template>
    </el-table-column>  

    <el-table-column
      property="ip"
      label="IP"
      >
    </el-table-column>


    <el-table-column
      property="port"
      label="Port"    
      >
    </el-table-column> 

    <el-table-column
      property="ver"
      label="Version"    
      >
    </el-table-column> 

    

    </el-table>

      
     <el-pagination
     background
     @current-change="pageChange" 
     layout="prev, pager, next"
     :current-page=currentPage 
     :page-size=pageSize 
     :total=total>
    </el-pagination>


 </div>    
</template>

<script>
  import { mapState, mapMutations } from "vuex"; // Add mapMutations
  export default {
    watch: {
    $route(to, from) {
        this.restoreParams();
    }
  }, 
  created() {},

  data() {
    return {
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      firstRow: 0,
      sortField: null,
      sortOrder: 0,
      currentRow: null
    };
  },

  mounted() {
    this.restoreParams();
  },

  computed: {
    localComputed() {},
    ...mapState(["devices_table"])
  },

   methods: {
       restoreParams() {
      let page = this.devices_table.page;
      this.firstRow = this.pageSize * (page - 1);
      this.$refs.usersTable.sort(
       this.devices_table.sortField,
       this.devices_table.sortOrder
      );      
      this.getItems();
    },
    addItem() {
      this.$router.push("/device/new");
    },
    onLog(){
      this.$router.push("/devices/logs");
    },

    tableRowClassName({ row, rowIndex }) {
      //if (this.socket.gw_list.indexOf(row.GW_id) < 0) {
      //  return "";
      //} else  {
      //  return "success-row";
     // }
      return "";
    },
    handleCurrentChange() {},
    onSort(event) {
      this.sortField = event.prop;
      if (event.order == "ascending") this.sortOrder = 1;
      else this.sortOrder = -1;

      console.log("sort");
      console.log(event);

      this.set_devices_table_sort({
        sortField: event.prop,
        sortOrder: event.order
      }); // save sort in store..
      this.getItems();
    },

    pageChange(page) {
      this.firstRow = this.pageSize * (page - 1);
      this.set_devices_table_page(page);
      this.getItems();
    },

    goHome() {
      this.$router.push("/");
    },

    getItems() {
      let params = {
        page : this.devices_table.page,
        pageSize : this.pageSize,
        sortField: this.sortField,
        sortOrder: this.sortOrder
      };
      
      this.$http
        .patch("/devices", params)
        .then(request => {
          console.log(request);
          this.tableData = request.data.data.data;
          this.total = request.data.data.total;
          this.currentPage = this.devices_table.page;
        })
        .catch(error => {
          console.log("auth error");
          console.log(error);
        });
    },


   },
   

  }
</script>

<style>
.my-green {
  color: rgb(9, 219, 37);
}

.my-gray {
  color: rgb(204, 207, 204);
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-table {
  color: rgb(121, 4, 4);
  padding: 5px 5px;
}

/*---------------------   http://www.colorzilla.com/gradient-editor/ ---*/
.el-table thead th {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=0 );

  padding: 5px 5px;
  color: #000;
}
</style>