import Vue from 'vue'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';
import VueAxios from 'vue-axios';
import store from './vuex/store';
import axios from './utils/axios';
import {requireAuth} from './utils/auth';
import Inputmask from 'inputmask';
import VueSSE from 'vue-sse';
import TreeView from "vue-json-tree-view"

import PrismEditor from "vue-prism-editor";
//load prism somewhere
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
//vue-prism-editor dependency
import "vue-prism-editor/dist/VuePrismEditor.css";


//---------------Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee,faUser, faUserFriends, faUserPlus, faTrashAlt, faMicrochip, faSitemap, faQuestion, faCompress, faCog} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCoffee, faUser, faUserFriends,faUserPlus, faTrashAlt, faMicrochip, faSitemap, faQuestion, faCompress, faCog)


//----------components------------------
import App from './App.vue'
import Home from './Home.vue';
import Welcome from './Welcome.vue';
import Login from './Users/Login.vue';
import User from './Users/User.vue';
import ChangePwd from './Users/ChangePwd.vue';
import Users from './Users/Users.vue';
import Devices from './Devices/Devices.vue';
import Device from './Devices/Device.vue';
import Logs from './Devices/Logs.vue';
import Device_setup from './Devices/Device_setup.vue';
import Device_channels from './Devices/Device_channels.vue';
import Device_scripts from './Devices/Device_scripts.vue';
import Device_script from './Devices/Device_script.vue';
import Device_files from './Devices/Device_files.vue';
import Device_control from './Devices/Device_control.vue';
import Firmware from './Firmware/Firmware.vue';
import Firmware_list from './Firmware/Firmware_list.vue';
import Firmware_upload from './Firmware/Firmware_upload.vue';
import Webhooks from './Webhooks/Webhooks.vue';
import Webhook from './Webhooks/Webhook.vue';
//import WebhookBuilder from './Webhooks/WebhookBuilder.vue';
//import WebhookTemplate from './Webhooks/WebhookTemplate.vue';






Vue.directive('mask', {
  bind: function (el, binding) {
      Inputmask(binding.value).mask(el.getElementsByTagName('INPUT')[0])
  }
})

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false


Vue.use(ElementUI, {
  size: 'small',
  zIndex: 3000,
  locale
});

Vue.use(TreeView);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(require('vue-moment'));
Vue.use(VueSSE);

const routes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/',
    beforeEnter: requireAuth,
    component: Home,
    children: [{
      path: '',
      name: 'Welcome',
      component: Welcome,
    }, 
    {
      path: 'users',
      name: 'Users',
      component: Users,      
      },

      
        
    {
      path: 'users/:userId',
      name: 'User',
      component: User,
      children: [
        {
          path: 'password',
          component: ChangePwd,
        }
      ]      
    },
    {
      path: 'devices',
      name: 'Devices',
      component: Devices,        
    },

    {
      path: 'device/:devID',
      name: 'Device',
      component: Device,
      children: [
        {
          path: 'setup',
          component: Device_setup,
        },
        {
          path: 'channels',
          component: Device_channels,
        },
        {
          path: 'script',
          component: Device_scripts,
        },
        {
          path: 'script/:scriptID',
          name: 'Script',
          component: Device_script,
        },
        {
          path: 'files',
          component: Device_files,
        },
        {
          path: 'control',
          component: Device_control,
        },

      ]    
    }, 

    {
      path: 'ota',
      name: 'ota',
      component: Firmware,  
    }, 

    {
      path: 'ota/upload',
      name: 'ota_upload',
      component: Firmware_upload,
    },

    {
      path: 'devices/logs',
      name: 'Logs',
      component: Logs,
    }, 

    {
      path: 'webhooks',
      name: 'Webhooks',
      component: Webhooks,        
    },
    {
       path: 'webhook/:webhookID',
       name: 'Webhook',
       component: Webhook,
       //children: [
       // {
       //   path: 'builder',
       //   component: WebhookBuilder,
       // }, 
       // {
       //   path: 'template',
       //   component: WebhookTemplate,
       // }, 
       //] 
    }

  ]

  },

  
  {
    path: '*',
    redirect: '/'
  },
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

new Vue({
  el: '#app',
  store, // inject store to all children
  router,
  axios,
  render: h => h(App)
})
