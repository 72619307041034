<template>
   <el-form ref="passForm" :model="passForm" status-icon :rules="pass_rules"  label-width="120px" size="mini" key="pass-form">
    <h3 class="title">Change password</h3>
    <el-form-item  label="New Password" prop="newpassword">
      <el-input type="password" v-model="passForm.newpassword" auto-complete="off"></el-input>
    </el-form-item>
    <el-form-item>
        <el-button type="primary" @click="submitNewPassword('passForm')">Submit new password</el-button>  
    </el-form-item>
      
                    
     
   </el-form>  
</template>
<script>
export default {
  data() {
    return {
      passForm: {
        newpassword: ""
      },
      pass_rules: {
        newpassword: [
          {required: true, message: "Please enter your password", trigger: "blur"},
          { min: 6, message: "Your password is too short!", trigger: 'blur' }
        ]        
      },
    };
  },

  

  methods: {
    submitNewPassword(formName) {      
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //alert('submit!');
            this.updatePassword(formName)
            this.$router.go(-1);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },


    updatePassword(formName){
       let command = {
        command: "change_password",
        params: {
          id: this.$route.params.userId,           
          password: this.passForm.newpassword,          
        }
      };
      this.$http
        .post("/users", command)
        .then(request => {
          console.log(request);
          if (request.data.result === "Error") {
            this.res_Error = "Error: " + request.data.error;
            return;
          }
          this.$message({
            type: "success",
            message: "A Password has been updated."
          });
          //this.$router.push("/users");
        })
        .catch(() => {
          console.log("auth error");
        });
    },
  }
};
</script>