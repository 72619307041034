<template>
  <div>
    <h1>Logs</h1>     
     <div style="height: 500px; text-align: left;">
       <h5>{{status}}</h5>
       <el-row :gutter="40">
         <el-col :span="12">
          
          <el-table
                          ref="logTable"
                          max-height="500"
                          :data="logData"
                          highlight-current-row
                          :default-sort = "{prop: 'time', order: 'descending'}"
                          @current-change="onCurrentLogChange"
                          @sort-change="onLogSortChange"
                    style="width: 100%">
                    <el-table-column                      
                      label="Time"
                      prop="time"
                      sortable
                      width="180">
                        <template slot-scope="scope">
                         <i class="el-icon-time"></i>
                         <span style="margin-left: 10px">{{ scope.row.time | moment("DD MMM YYYY hh:mm:ss a") }}</span>
                        </template>                      
                    </el-table-column>
                    <el-table-column
                       prop="event"
                       label="Event"
                      width="180">
                    </el-table-column>   
                    <el-table-column
                       prop="coreid"
                       label="Core ID"
                    >
                    </el-table-column> 

                    <el-table-column
                       prop="data"
                       label="Data"
                    >
                    </el-table-column> 


                  </el-table>
         </el-col>

         
        <el-col :span="12">
                      <el-checkbox v-model="showLastEvent">Show Last Event</el-checkbox>
                      <tree-view v-if="currentLog != null" :data="currentLog" :options="{maxDepth: 0, rootObjectKey: 'Packet'}"></tree-view> 
                      <div v-else>
                        Select packet..
                      </div>
        </el-col>
       </el-row>
         <!--<p v-for="message in messages" :key="message.event">{{ message }}</p>-->
     </div>     
  </div>

</template>

<script>
// We store the reference to the SSE object out here
// so we can access it from other methods
let msgServer;
//const EVENTS_URL = process.env.EVENTS_URL || 'http://oak-art.ca:3003/events';
const EVENTS_URL = process.env.EVENTS_URL || 'http://localhost:3007/events';

export default {
  name: 'sse-test',
  data() {
    return {
      messages: [],
      logData:[],
      showLastEvent: false,
      status: "Listening..",
      currentLog: null,
      currentSortOrder: "descending"   
    };
  },
  mounted() {
    //this.$sse('http://localhost:3003/events') // or { format: 'plain' }
    this.$sse(EVENTS_URL) // or { format: 'plain' }
      .then(sse => {
        // Store SSE object at a higher scope
        msgServer = sse;        

        // Listen for messages without a specified event
        sse.subscribe('', data => {
          //console.warn('Received a message w/o an event!', data);
          this.messages.push(data);
          let x = JSON.parse(data); 
          x.time = new Date(); 
          let packet = {};
          packet.packet = x;        
          this.logData.push(x);
          if (this.showLastEvent) this.currentLog = x;      
          this.$refs.logTable.sort('time', this.currentSortOrder);
        });    
        
      })
      .catch(err => {
        // When this error is caught, it means the initial connection to the
        // events server failed.  No automatic attempts to reconnect will be made.
        console.error('Failed to connect to server', err);
        status = 'Failed to connect to server';
      });
  },
  beforeDestroy() {
    // Make sure to close the connection with the events server
    // when the component is destroyed, or we'll have ghost connections!
    msgServer.close();
  },

  methods: {
    onCurrentLogChange(val){
      this.currentLog = val;
    },

    onLogSortChange(event){      
         this.currentSortOrder = event.order;
         //alert(this.currentSortOrder);
    },
  },
};
</script>

<style>
/* The Tree View should only fill out available space, scroll when 
   necessary.
*/


.tree-view-item {
  font-family: monospace;
  font-size: 14px;
  margin-left: 18px;
}

.tree-view-wrapper {
  overflow: auto;
}

/* Find the first nested node and override the indentation */
.tree-view-item-root > .tree-view-item-leaf > .tree-view-item {
  margin-left: 0;
}

/* Root node should not be indented */
.tree-view-item-root {
  margin-left: 0;
}

.tree-view-item-node {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}

.tree-view-item-leaf {
  white-space: nowrap;
}

.tree-view-item-key {
  font-weight: bold;
}

.tree-view-item-key-with-chevron {
  padding-left: 14px;
}


.tree-view-item-key-with-chevron.opened::before {
    top:4px;
    transform: rotate(90deg);  
    -webkit-transform: rotate(90deg);
}

.tree-view-item-key-with-chevron::before {
    color: #444;
    content: '\25b6';
    font-size: 10px;
    left: 1px;
    position: absolute;
    top: 3px;
    transition: -webkit-transform .1s ease;
    transition: transform .1s ease;
    transition: transform .1s ease, -webkit-transform .1s ease;
    -webkit-transition: -webkit-transform .1s ease;
}

.tree-view-item-hint {
  color: #ccc
}

</style>