<template>
  <!--<div class="container">
    <el-row>
       <label>File
        <el-input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
      </label>
        <button v-on:click="submitFile()">Submit</button>
    
  </el-row>-->
  <div>
    
  <el-row>
    <el-col :span="6">
      <div style="">
        <span style="display: inline-block;float: left;">New Version</span>
        <el-input style="display: inline-block; float: right;"
        placeholder="Input version"        
        v-mask="{mask: '9999999999', autoUnmask: true, placeholder: ' '}"      
        v-model="file_version">
      </el-input>
     </div>
    </el-col>
  </el-row>
  
  
  <el-row>
    <el-col :span="6">
      <div style="">
        <span style="display: inline-block;float: left;">Description</span>
        <el-input type="textarea"
        :rows="2"
        placeholder="Input description"
        v-model="file_description">
      </el-input>
     </div>
    </el-col>
  </el-row>
  
  <el-row>
  <el-col :span="12">   
  <div style="display: inline-block;float: left;">
  
  <el-upload
  :multiple="false"
  action=""  
  class="upload-demo"
  ref="upload"
  :http-request="uploadSectionFile"  
  :auto-upload="false"> 

  <el-button slot="trigger" size="small" type="primary" :disabled="file_version == ''">select file</el-button>
  <el-button style="margin-left: 10px;" size="small" type="success" :disabled="file_version == ''|| file_description == ''" @click="submitFile()">upload to server</el-button>
  <!--<div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div>-->
  <!--<el-tag v-show="showSectionFileName" type="gray">{{sectionFileName}}</el-tag>-->
</el-upload>
</div>
</el-col>
</el-row>

</div>
    
    <!--<div class="large-12 medium-12 small-12 cell">
      <label>File
        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
      </label>
        <button v-on:click="submitFile()">Submit</button>
    </div>
  </div>-->
</template>


<script>
  export default {
    data(){
       return {
       file: '',      
       file_version:"",
       file_description:"",
     }
    },   

    methods: {
      handleFileUpload(){
          this.file = this.$refs.file.files[0];
          
      },

      uploadSectionFile(param){
        console.log('uploadSectionFile');
        var that = this; 
        //this.$message({
        //    type: "success",
        //    message: "File uploaded!."
        //  });

        var fileObj = param.file;
        var formData = new FormData(); 
        formData.append("description", this.file_description); 
        formData.append("file_name", this.file_version);  
        formData.append("file", fileObj, this.file_version + '.bin');
        this.axios.post( '/fw',
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }
            ).then(()=>{
          console.log('SUCCESS!!');
          this.$router.push("/ota");
          that.$message({
            type: "success",
            message: "File uploaded!."
          });           
        })
        .catch(function(){
            console.log('FAILURE!!');            
        });
      },

      submitFile(){
          this.$refs.upload.submit();
      },


      submitFile1(){
          let formData = new FormData();
          this.file.my_name = 'test.bin';
          console.log('file:');console.log(this.file); 
          formData.append('file', this.file, "test.bin");          

          this.axios.post( '/fw',
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }
            ).then(function(){
          console.log('SUCCESS!!');
        })
        .catch(function(){
          console.log('FAILURE!!');
        });
      },
    }
  }
</script>

<style>
  .demo-input-label {
    display: inline-block;
    width: 130px;
  }
</style>