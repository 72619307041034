<template>
  <el-form :model="ruleForm2" status-icon :rules="rules2" ref="ruleForm2" label-width="120px" class="login-container">
    <h3 class="title">Please Login</h3>
      <el-form-item>
          <el-tag v-show="Error_Message !== ''"  type="danger">{{Error_Message}}</el-tag>
      </el-form-item>
      <el-form-item label="User Name" prop="username">
        <el-input   v-model="ruleForm2.username" auto-complete="off"></el-input> 
      </el-form-item> 
      <el-form-item label="Password" prop="pass">
        <el-input type="password" v-model="ruleForm2.pass" auto-complete="off"></el-input>
    </el-form-item>
    <el-form-item>
        <el-checkbox v-model="ruleForm2.checked" >Remember me</el-checkbox>
    </el-form-item>
    <el-form-item>
    <el-button type="primary" @click="submitForm('ruleForm2')">Submit</el-button>    
  </el-form-item>      
  </el-form>  
</template>


<script>
import { setAccessToken } from "../utils/auth";
import { mapState, mapMutations } from "vuex"; // Add mapMutations
import axios from "axios";
import EventBus from '../utils/event-bus'; 
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password"));
      } else {
        callback();
      }
    };

    var validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the User Name"));
      } else {
        callback();
      }
    };

    return {
      Error_Message: "",
      ruleForm2: {
        username: "",
        pass: "",
        checked: false
      },
      rules2: {
        username: [{ validator: validateName, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }]
      }
    };
  },
  methods: {
    ...mapMutations(["set_profile"]),

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$http
            .post("/authenticate", this.ruleForm2)
            .then(request => {
              console.log("auth succes");
              console.log(request);
              if (request.data.success) {
                setAccessToken(JSON.stringify(request.data.user));
                this.set_profile(request.data.user);
                //axios.defaults.headers.common['x-access-token1'] = request.data.user.token;
                if (this.$route.query.redirect != null) {
                  console.log("redirecting to " + this.$route.query.redirect);
                  this.$router.push(this.$route.query.redirect);
                } else this.$router.push("/");

                //this.$router.replace("/users");
              } else {
                this.Error_Message = request.data.message;
              }
            })
            .catch(() => {
              console.log("auth error");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  },
  mounted() {
    EventBus.$on("Network error", function(payLoad) {     
      this.$message.error(payLoad.message);
    });
  }
};
</script>





<style  scoped>
.login-container {
  /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
.title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}
.remember {
  margin: 0px 0px 35px 0px;
}
</style>