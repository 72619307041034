/* global localStorage */

import axios from 'axios'
import EventBus from './event-bus'; //./utils/auth



const API_URL = process.env.API_URL || 'http://localhost:3007';
//const API_URL = process.env.API_URL || 'http://localhost:3007/api';
//const API_URL = process.env.API_URL || 'https://admin.com/api'
//const API_URL = process.env.API_URL || 'https://iot.oak-art.ca/api';

var get_token = function () {
  if (localStorage.getItem('ACCESS_TOKEN_KEY')) {
    let user = JSON.parse(localStorage.getItem('ACCESS_TOKEN_KEY'));
    let jwt = user.token;
    return jwt;
  } else {
    return '';
  }
}

//export default axios.create({
//  baseURL: API_URL,
//  headers: {
//    'Content-Type': 'application/json',
//    'x-access-token': get_token(),    
//  }
//})

const request = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
})


request.interceptors.request.use(request => {
  //alert('get token: '+ get_token());
  request.headers.common['x-access-token'] = get_token();
  request.headers.common['Authorization'] = "Bearer "+ get_token();
  return request

}, error => {
  return Promise.reject(error)
})


request.interceptors.response.use(response => {
  return response
}, error => {
  console.log('http Error');
  //console.log(error);
  if (error.message === "Network Error") {
    EventBus.$emit('Network error', {
      type: 'network',
      message: "Network Error"
    });
    return Promise.reject(error);
  }
  //alert(error.response.status);
  switch (error.response.status) {
    case 401:
      EventBus.$emit('Network error', {
        type: 'network',
        message: error.response.data.error
      });
      break
    case 403:
      EventBus.$emit('Network error', {
        type: 'network',
        message: error.response.data.message
      });
      break
    case 500:
      EventBus.$emit('Network error', {
        type: 'network',
        message: error.response.data.message
      });
      break
    default:
      console.log('Error')
  }
  return Promise.reject(error)
})


export default request





/*
const API_URL = process.env.API_URL || 'http://192.168.100.109:8585/api'

const VueAxios = axios.create({
  baseURL: API_URL,
  timeout: 10000
})

if (localStorage.getItem('ACCESS_TOKEN_KEY')) {
  let user = JSON.parse(localStorage.getItem('ACCESS_TOKEN_KEY'));      
  let jwt = user.token;
  VueAxios.defaults.headers.common['x-access-token'] = jwt;
} else {
  VueAxios.defaults.headers.common['x-access-token'] = ''
}

export default VueAxios*/
